<template>
	<transition name="fade">
		<a href="#" title="Запустить таймер" class="mr-1" v-if="!current_timer" @click.prevent="startTimer()">
			<span v-if="!loading" class="badge badge-outline-secondary greenish-badge">{{ timePassedFormat(time_spent) }} <i class="mdi mdi-timer"></i></span>
			<span v-else class="badge badge-outline-danger greenish-badge">{{ timePassedFormat(time_spent) }} <i class="spinner-border spinner-border-sm" role="status"></i></span>
		</a>
		<a href="#" title="Завершить таймер" class="mr-1" v-if="current_timer && isObjectTimer(current_timer)" @click.prevent="stopTimer()">
			<span v-if="!loading" class="badge badge-outline-danger">{{ timePassedFormat(time_spent_live) }} <i class="mdi mdi-timer"></i></span>
			<span v-else class="badge badge-outline-secondary greenish-badge">{{ timePassedFormat(time_spent) }} <i class="spinner-border spinner-border-sm" role="status"></i></span>
		</a>
	</transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'TimerBadge',
	props: [
		'timers',
		'object'
	],
	data: () => {
		return {
			loading: false
		}
	},
	computed: {
		...mapState({
			current_timer: state => state.timer.current_timer,
			currentTimerPassed: state => state.timer.currentTimerPassed
		}),
		...mapGetters({
			timePassedFormat: 'timer/timePassedFormat'
		}),
		time_spent_live() {
			return this.time_spent + this.currentTimerPassed
		},
		time_spent() {
			if(!this.timers) return ''

			let timePassed = this.timers.reduce((acc, timer) => {
				let timeDelta = 0
				if(!this.isObjectTimer(timer)) return acc;
				if(!timer.time_finish) return acc;

				return acc += (new Date(timer.time_finish) - new Date(timer.time_start))/1000
			}, 0)

			return parseInt(timePassed)
		}
	},
	methods: {
		isObjectTimer(timer) {
			if(this.object.project_id && (!timer.project_id || timer.project_id != this.object.project_id)) return false;
			if(this.object.task_id && (!timer.task_id || timer.task_id != this.object.task_id)) return false;
			if(this.object.subtask_id && (!timer.subtask_id || timer.subtask_id != this.object.subtask_id)) return false;

			return true;
		},
		async startTimer() {
			this.loading = true
			await this.$store.dispatch('timer/startTimer', {$orm: this.$orm, object: this.object})
			setTimeout(() => {
				this.loading = false
			}, 300)
		},
		async stopTimer() {
			this.loading = true
			await this.$store.dispatch('timer/finishTimer', {$orm: this.$orm, object: this.object})
			setTimeout(() => {
				this.loading = false
			}, 300)
		}
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.greenish-badge:hover {
    color: #10c469;
    border-color: #10c469;
    transition: all 0.4s ease;
}
</style> 