<template>
    <div v-if="color" class="color-badge" :style="{ 'border-color': color }"></div>
</template>

<script>
export default {
    name: 'ColorBadge',
    props: ['project'],
    computed: {
        color() {
            return this.project.color.length ? this.project.color[0].color : null
        }
    }
}
//padding: 0.75rem 1.5rem;
</script>

<style scoped>
.color-badge {
    float: left;
    height: 0px;
    width: 0px;

    border-left: 12px solid;
    border-top: 12px solid;
    border-right: 24px solid transparent !important;
    border-bottom: 24px solid transparent !important;

    margin-top: -0.75rem;
    margin-left: -1.5rem;
    margin-right: -1rem;
}
</style>