<template>
	<transition name="fade" v-if="time_spent">
		<a title="Потрачено времени" class="mr-1">
			<span class="badge badge-outline-secondary ">{{ timePassedFormat(time_spent) }} <i class="mdi mdi-timer"></i></span>
		</a>
	</transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'TimerBadge',
	props: [
		'timers',
		'object'
	],
	computed: {
		...mapState({
			current_timer: state => state.timer.current_timer,
			currentTimerPassed: state => state.timer.currentTimerPassed
		}),
		...mapGetters({
			timePassedFormat: 'timer/timePassedFormat'
		}),
		time_spent() {
			if(!this.timers) return ''

			let timePassed = this.timers.reduce((acc, timer) => {
				let timeDelta = 0
				if(!this.isObjectTimer(timer)) return acc;
				if(!timer.time_finish) return acc;

				return acc += (new Date(timer.time_finish) - new Date(timer.time_start))/1000
			}, 0)

			return parseInt(timePassed)
		}
	},
	methods: {
		isObjectTimer(timer) {
			if(this.object.project_id && (!timer.project_id || timer.project_id != this.object.project_id)) return false;
			if(this.object.task_id && (!timer.task_id || timer.task_id != this.object.task_id)) return false;
			if(this.object.subtask_id && (!timer.subtask_id || timer.subtask_id != this.object.subtask_id)) return false;

			return true;
		}
	}
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.greenish-badge:hover {
    color: #10c469;
    border-color: #10c469;
    transition: all 0.4s ease;
}
</style> 