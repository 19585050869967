<template>
    <p class="mb-1">
        <span class="pr-3 text-nowrap mb-2 d-inline-block">
            <i class="mdi mdi-format-list-bulleted-type text-muted mr-1"></i> 
            Задач: <b>{{ task.active }}</b> / <small>{{ task.active + task.finished }}</small>
        </span>

        <span class="pr-3 text-nowrap mb-2 d-inline-block">
            <i class="mdi mdi-format-list-checks text-muted mr-1"></i> 
            Подзадач: <b>{{ subtask.active }}</b> / <small>{{ subtask.active + subtask.finished }}</small>
        </span>
    </p>
</template>

<script>
function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
export default {
    name: 'ProjectCardStats',
    props: ['project'],
    data: () => {
        return {
            task: {
                active: 0,
                finished: 0
            },
            subtask: {
                active: 0,
                finished: 0
            }
        }
    },
    computed: {
        taskCount() {
            return this.project.tasks.reduce((ack, item) => {
                return ack += (item.is_finished === true ? 0 : 1)
            }, 0)
        },
        subtaskCount() {
            return this.project.tasks.reduce((ack, item) => {
                if(item.is_finished) return ack;

                return ack += item.subtasks.reduce((ack2, item2) => {
                    return ack2 += (item2.is_finished === true ? 0 : 1)
                }, 0)
            }, 0)
        },
    },
    methods: {
        initiate() {
            this.task = {
                active: 0,
                finished: 0
            };
            this.subtask = {
                active: 0,
                finished: 0
            };
            
            this.project.tasks.forEach(async task => {
                if(task.is_finished) {
                    this.task.finished++
                    this.subtask.finished += task.subtasks.length
                } else {
                    this.task.active++
                    task.subtasks.forEach(async subtask => {
                        if(subtask.is_finished) this.subtask.finished++
                        else this.subtask.active++
                    })
                }
            })
        }
    },
    mounted() {
        this.initiate()
    },
    watch: {
        project: function() {
            this.initiate()
        }
    }
}
</script>

<style>

</style>