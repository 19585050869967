<template>
    <div class="card-widgets">
        <timer-badge :object="{ project_id: project.id }" :timers="project.timers"/>

        <router-link :to="{ name: 'TimerList', params: { project_id: project.id } }" title="Таймеры проекта" >
            <i class="mdi mdi-timetable"></i>
        </router-link>

        <a title="Завершить проект" v-if="!project.is_finished"  @click.prevent="finishItem()">
            <i v-if="!loading.finish" class=" mdi mdi-check-bold mr-1"></i>
            <i v-else class="spinner-border spinner-border-sm mr-1"></i>
        </a>

        <a title="Добавить в избранное" @click.prevent="toggleFavorite()">
            <i v-if="!loading.favorite" class="mdi mr-1" :class="{ 'mdi mdi-star-outline': !isFavorited, 'mdi-star': isFavorited }"></i>
            <i v-else class="spinner-border spinner-border-sm mr-1"></i>
        </a>

        <a v-if="project_description" title="Редактировать описание" @click.prevent="project_description.editValue()">
            <i class="mdi mdi-clipboard-text-outline mr-1"></i>
        </a>

        <a title="Удалить проект" v-if="!project.is_deleted"  @click.prevent="deleteItem()">
            <i v-if="!loading.delete" class=" mdi mdi-delete"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
        </a>
    </div>
</template>

<script>
import TimerBadge from '@/components/timer/TimerBadge.vue'

export default {
    name: 'ProjectCardWidget',
    props: ['project', 'project_description'],
    data: () => {
        return {
            loading: {
                finish: false,
                delete: false,
                favorite: false
            }
        }
    },
    computed: {
        isFavorited() {
            return this.project.favorited_by && this.project.favorited_by.length
        }
    },
    methods: {
        async deleteItem() {
            if(!confirm(`Удалить ${this.project.title} ?`))
                return false

            this.loading.delete = true
            var [err, response] = await this.$orm.mutate({
                project: {
                    update: {
                        where: { id: { _eq: this.project.id }},
                        _set: { is_deleted: true }
                    }
                }
            })
            if(!err && !response.error)
                this.project.is_deleted = true
            
            this.loading.delete = false
        },
        async finishItem() {
            if(!confirm(`Завершить ${this.project.title} ?`))
                return false

            this.loading.finish = true
            var [err, response] = await this.$orm.mutate({
                project: {
                    update: {
                        where: { id: { _eq: this.project.id }},
                        _set: { is_finished: true, finished_by_id: this.$store.state.auth.user.id, finished_at: 'now()' }
                    }
                }
            })
            if(!err && !response.error)
                this.project.is_finished = true
            
            this.loading.finish = false
        },
        async toggleFavorite() {
            this.loading.favorite = true

            if(this.isFavorited) {
                var [err, response] = await this.$orm.mutate({
                    project_favorite: {
                        delete: {
                            where: { id: { _eq: this.project.favorited_by[0].id } }
                        }
                    }
                }, { getFirst: true })
                if(!err && !response.error)
                    this.project.favorited_by = null
            } else {
                var [err, response] = await this.$orm.mutate({
                    project_favorite: {
                        insert: {
                            objects: {
                                project_id: this.project.id
                            }
                        }
                    }
                }, { getFirst: true })
                if(!err && !response.error)
                    this.project.favorited_by = [response]
            }


            this.loading.favorite = false
        }
    },
    components: {
        TimerBadge
    }
}
</script>

<style scoped>
.card-widgets {
    float: right;
    height: 24px;
    margin-top: 1px;
    margin-bottom: 2px;
    z-index: 2;
    position: relative;
    padding-left: 24px;
}
.card-widgets i {
    padding: 0px 2px;
    border-radius: 4px;
    transition: 150ms all ease;
}
.card-widgets i:hover {
    background-color: rgba(0,0,0,.05);
}
.card-widgets a {
    vertical-align: middle;
}
</style>