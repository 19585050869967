<template>
	<ul class="list-group list-group-flush mb-0" v-if="project.tasks.length">
		<li class="list-group-item p-3">

			<p class="mb-2 font-weight-bold">Прогресс <span class="float-right">{{ percent }}%</span></p>
			<div class="progress progress-sm">
				<div class="progress-bar" role="progressbar" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100" :style="{ width: `${percent < 1 ? 1 : percent}%`}">
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'ProjectProgress',
	props: [ 'project' ],
	data: () => {
		return {
			
		}
	},
	computed: {
		percent() {
			let itemsCount = 0, 
				itemsFinished = 0

			this.project.tasks.forEach(task => {
				if(!task.subtasks || !task.subtasks.length) {
					itemsCount++
					if(task.is_finished) itemsFinished++
				} else {
					task.subtasks.forEach(subtask => {
						itemsCount++
						if(subtask.is_finished) itemsFinished++
					})
				}				
			})

			return parseInt( 
				itemsFinished * 100 / itemsCount
			)
		}
	},
	methods: {
		
	}
}
</script>

<style scoped>
.list-group-item {
	padding-top: 0px !important;
}
</style>