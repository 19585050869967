<template>
    <div>
        <a href="javascript:void(0);" v-if="project.owner_id === user.id" title="Добавить в проект" class="d-inline-block add_to_project mr-2" @click="$store.dispatch('modal/open_invite2project', project.id)">
           <i class="mdi mdi-account-plus"></i>
        </a>

        <a href="javascript:void(0);" :title="project.owner.name" class="d-inline-block mr-1">
            <profile-avatar :user="project.owner" gclass="rounded-circle avatar-xs" />
        </a>

        <a href="javascript:void(0);" v-for="guest in project.guests" :key="guest.id" :title="guest.user.name" class="d-inline-block" >
            <profile-avatar :user="guest.user" :gclass="{ 'guest-awaits': !guest.is_confirmed, 'rounded-circle avatar-xs': true }" />
        </a>

        
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ProfileAvatar from '@/components/profile/Avatar.vue'

export default {
    name: 'ProjectCardMembers',
    props: ['project'],
    computed: {
		...mapState({
			user: state => state.auth.user
        }),
    },
    components: {
        ProfileAvatar
    }
}
</script>

<style>
.add_to_project {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #536de6;
    text-align: center;
    color: white;
}
.add_to_project:hover {
    color: white;
    opacity: 0.9;
}
.guest-awaits {
    opacity: 0.5;
    border:1px dashed var(--gray-dark);
}
.guest-awaits:hover {
    opacity: 1;
}
</style>